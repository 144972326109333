import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const StyledContact = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background-color: ${({ theme }) => theme.body};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  ${({ theme }) => theme.media.desktop} {
    
    padding: 40px 0 0 0;
    min-height: calc(100vh - 390px);
    align-items: center;
   
  }
`;

const StyleWrapper = styled.div`
  width: 95%;
  height: auto;
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
    max-width: 1070px;
    height: auto;
  }
  ${({ theme }) => theme.media.large} {
    width: 70%;
    max-width: 1070px;
    height: auto;
  }
`;
const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  margin-bottom: 40px;
`;

const StyledTitile = styled.div`
  padding: 6px 18px;
  background-color: rgba(246, 150, 0, 1);
  font-weight: 400;
  color: rgba(0, 90, 180, 1);
`;
const StyledH2 = styled.h2`
  font-size: ${({ theme }) => theme.font.size.xm};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: white;
  font-weight: 500;
`;

const StyledInner = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgba(255, 255, 255, 0.7);

  ${({ theme }) => theme.media.desktop} {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, 0.7);
    flex: 1;
  }
`;

const StyledDes = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
  }
`;

const StyledTop = styled.div`
  width: 100%;
  height: auto;
`;

const StyledLeft = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 5px;
  ${({ theme }) => theme.media.desktop} {
    width: 49%;
    margin-right: 2%;
  }
`;

const StyledRight = styled.div`
  width: 100%;
  height: auto;
  ${({ theme }) => theme.media.desktop} {
    width: 49%;
    margin-top: 5px;
  }
`;

const H3 = styled.div`
  color: ${({ isBlue }) =>
    isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
  font-size: ${({ theme }) => theme.font.size.s};
  margin-top: 10px;
  margin-bottom: 10px;
  ${({ theme }) => theme.media.desktop} {
    color: ${({ isBlue }) =>
      isBlue ? ({ theme }) => theme.blue : ({ theme }) => theme.blackfont};
    font-size: ${({ theme }) => theme.font.size.s};
    margin-top: ${({ isTop }) => (isTop ? "25px" : "10px")};
    margin-bottom: 15px;
  }
`;
const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: black;
  font-weight:500;
  margin: 4px 0 !important;
  margin-bottom: ${({ isDown }) => (isDown ? "10px" : "0px")};
  margin-top: ${({ isTop }) => (isTop ? "10px" : "0px")};
  
  p{
    a{
      color:${({ theme }) => theme.blackfont};
      
      &:hover{
        color: ${({ theme }) => theme.blue}
      }
    }
  }
`;

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsKontakt(sort: { fields: title }) {
        nodes {
          id
          title
          nameShop
          linkShop
          leftcontact {
            ... on DatoCmsHeadtitle {
              id
              headtitilehtml
            }
            ... on DatoCmsTextcontact {
              id
              textcontact
            }
            ... on DatoCmsHeadtitleblue {
              id
              headtitleblue
            }
          }
          rightcontact {
            ... on DatoCmsHeadtitler {
              id
              headtitle
            }
            ... on DatoCmsHeadtitlebluer {
              id
              headtitilebluer
            }
            ... on DatoCmsTextcontactr {
              id
              textcontact
            }
          }
        }
      }
    }
  `);
  return (
    <StyledContact>
      <StyleWrapper>
        {data.allDatoCmsKontakt.nodes.map((allDatoCmsKontakt) => (
          <StyledDiv key={allDatoCmsKontakt.id}>
            <StyledTitile>
              <StyledH2>{allDatoCmsKontakt.title}</StyledH2>
            </StyledTitile>
            <StyledInner>
              <StyledTop>
                <H3
                  dangerouslySetInnerHTML={{
                    __html: allDatoCmsKontakt.nameShop,
                  }}
                />
                <StyledP
                  dangerouslySetInnerHTML={{
                    __html: allDatoCmsKontakt.linkShop,
                  }}
                />
              </StyledTop>

              <StyledDes>
                <StyledLeft>
                  {allDatoCmsKontakt.leftcontact.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "headtitilehtml":
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitilehtml,
                            }}
                          />
                        );
                      case "headtitleblue":
                        return (
                          <H3
                            isBlue
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitleblue,
                            }}
                          />
                        );
                      case "textcontact":
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontact,
                            }}
                          />
                        );

                      default:
                        return null;
                    }
                  })}
                </StyledLeft>
                <StyledRight>
                  {allDatoCmsKontakt.rightcontact.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "headtitle":
                        // eslint-disable-next-line react/no-danger
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{ __html: item.headtitle }}
                          />
                        );
                      case "headtitilebluer":
                        // eslint-disable-next-line react/no-danger
                        return (
                          <H3
                            isBlue
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitilebluer,
                            }}
                          />
                        );
                      case "textcontact":
                        // eslint-disable-next-line react/no-danger
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontact,
                            }}
                          />
                        );

                      default:
                        return null;
                    }
                  })}
                </StyledRight>
              </StyledDes>
            </StyledInner>
          </StyledDiv>
        ))}
      </StyleWrapper>
    </StyledContact>
  );
};
export default Contact;
